import { render, staticRenderFns } from "./NestedDatatable.vue?vue&type=template&id=651416e2&scoped=true"
import script from "./NestedDatatable.vue?vue&type=script&lang=js"
export * from "./NestedDatatable.vue?vue&type=script&lang=js"
import style0 from "./NestedDatatable.vue?vue&type=style&index=0&id=651416e2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651416e2",
  null
  
)

export default component.exports